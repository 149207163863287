<template>
  <ComponentLoader :components="getSpecificPage" />
  <section class="component-section component-section--content">
    <AdventCalendar />
  </section>
  <div class="snowflakes" aria-hidden="true">
    <div class="snowflake">❄</div>
    <div class="snowflake">❄</div>
    <div class="snowflake">❄</div>
    <div class="snowflake">❄</div>
    <div class="snowflake">❄</div>
    <div class="snowflake">❄</div>
    <div class="snowflake">❄</div>
    <div class="snowflake">❄</div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex';
import ComponentLoader from '../components/ComponentLoader.vue';
import AdventCalendar from '@/components/AdventCalendar.vue';

export default {
  name: 'base-page',
  components: {
    ComponentLoader,
    AdventCalendar,
  },
  data() {
    return {
      pageHeight: 0,
    };
  },
  computed: {
    ...mapGetters(['getPagesEN', 'getPagesDE', 'getLanguage']),

    getSpecificPage() {
      const page =
        this.getLanguage == 'de-DE' ? this.getPagesDE : this.getPagesEN;

      const specificPage = page.find((page) => {
        const pageSlug =
          page.fields.slug !== '/' ? '/' + page.fields.slug : '/';
        return pageSlug == window.location.pathname;
      });
      return specificPage?.fields?.reference;
    },
  },
};
</script>

<style lang="scss">
@keyframes fall {
  0% {
    opacity: 1;
    transform: translateY(-10vh) translateX(0);
  }
  100% {
    opacity: 1;
    transform: translateY(105vh) translateX(5vw);
  }
}

section.component-section--content {
  margin-bottom: 0;
  padding-bottom: 0;
  position: relative;
}

.snowflakes {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999;
}

.snowflake {
  position: absolute;
  top: -10%;
  z-index: 10;
  user-select: none;
  pointer-events: none;
  color: #fff;
  font-size: 1em;
  animation-name: fall;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  opacity: 0;
  transition: all 0.2s ease;
}

.snowflake:nth-child(1) {
  left: 10%;
  animation-duration: 10s;
  animation-delay: 0s;
  font-size: 2em;
}

.snowflake:nth-child(2) {
  left: 20%;
  animation-duration: 12s;
  animation-delay: 2s;
  font-size: 1.5em;
}

.snowflake:nth-child(3) {
  left: 30%;
  animation-duration: 8s;
  animation-delay: 4s;
  font-size: 1.2em;
}

.snowflake:nth-child(4) {
  left: 40%;
  animation-duration: 14s;
  animation-delay: 6s;
  font-size: 1.8em;
}

.snowflake:nth-child(5) {
  left: 50%;
  animation-duration: 10s;
  animation-delay: 8s;
  font-size: 1.6em;
}

.snowflake:nth-child(6) {
  left: 60%;
  animation-duration: 11s;
  animation-delay: 10s;
  font-size: 1.4em;
}

.snowflake:nth-child(7) {
  left: 70%;
  animation-duration: 9s;
  animation-delay: 12s;
  font-size: 1.3em;
}

.snowflake:nth-child(8) {
  left: 80%;
  animation-duration: 13s;
  animation-delay: 14s;
  font-size: 1.7em;
}
</style>
