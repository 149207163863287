<template>
  <div class="advent-calendar">
    <div
      v-for="(cosplayer, index) in getChristmasFeatureList"
      :key="`day${index + 1}`"
      :ref="`day${index + 1}`"
      class="door"
      :class="getClass(index)"
      @click="() => openDoor(index)"
    >
      <div :class="['door-content']">
        <div class="door-number">{{ cosplayer.fields.id }}</div>

        <div
          v-if="clickedDoorIndex === index && modalData === null"
          class="door-prevent"
        >
          <img src="../assets/images/poro_sewcase.png" alt="Not yet!" />
          <div v-if="this.getLanguage == 'de-DE'" class="speech-bubble">
            Noch nicht 😊!
          </div>
          <div v-else class="speech-bubble">Not yet 😊!</div>
        </div>
      </div>
    </div>

    <div v-if="modalData" class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        <div
          class="modal-background"
          :style="{
            backgroundImage: `url(${modalData.image?.fields?.file?.url})`,
          }"
        >
          <div
            v-if="modalData.name && modalData.description"
            class="advent-info"
          >
            <h2>{{ modalData.name }}</h2>
            <div class="advent-text">
              <RichTextRenderer :document="modalData.description" />
            </div>

            <template v-if="modalData.socialMediaUrl?.includes(';')">
              <template
                v-for="(url, i) in modalData.socialMediaUrl.split(';')"
                :key="i"
              >
                <a class="advent-social" :href="url" target="_blank">
                  <img src="../assets/images/insta.png" alt="favicon" />
                  @{{ extractInstagramName(url) }}
                </a>
              </template>
            </template>

            <template v-else>
              <a
                class="advent-social"
                :href="modalData.socialMediaUrl"
                target="_blank"
              >
                <img src="../assets/images/insta.png" alt="favicon" />
                @{{ extractInstagramName(modalData.socialMediaUrl) }}
              </a>
            </template>

            <div v-if="modalData.photographerUrl">
              <a
                class="advent-social"
                :href="modalData.photographerUrl"
                target="_blank"
              >
                <span>📷</span>
                @{{ modalData.photographerName }}
              </a>
            </div>

            <div v-else-if="modalData.photographerName" class="advent-social">
              <span>📷</span>
              {{ modalData.photographerName }}
            </div>
          </div>
          <button class="close-button" @click="closeModal">&times;</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex';
import RichTextRenderer from 'contentful-rich-text-vue-renderer';

export default {
  name: 'AdventCalendar',
  components: {
    RichTextRenderer,
  },
  data() {
    return {
      todayDay: new Date().getDate(),
      modalData: null,
      clickedDoorIndex: null,
    };
  },
  computed: {
    ...mapGetters(['getChristmasFeatureList', 'getLanguage']),
  },
  methods: {
    openDoor(index) {
      const currentMonth = new Date().getMonth();
      const params = new URLSearchParams(window.location.search);
      const allowAllDoors = params.get('whosyourdaddy') === '1';

      if (
        !allowAllDoors &&
        (currentMonth !== 11 || index + 1 > this.todayDay)
      ) {
        this.clickedDoorIndex = index;
        this.modalData = null;
      } else {
        this.modalData = this.getChristmasFeatureList[index]?.fields;
        this.clickedDoorIndex = null;
      }
    },
    extractInstagramName(url) {
      const parts = url.split('/');
      return parts[parts.length - 2];
    },
    scrollToDay(day) {
      const element = this.$refs['day' + day][0];
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    closeModal() {
      this.modalData = null;
    },
    getClass(index) {
      const doorNumber = (index % 9) + 1;
      console.log(
        index + 1 <= this.todayDay,
        index + 1 === Math.min(this.todayDay, 24)
      );
      return [
        `door-${doorNumber}`,
        { 'door-today': index + 1 === Math.min(this.todayDay, 24) },
      ];
    },
  },
  unmounted() {
    this.modalData = null;
  },
};
</script>

<style lang="scss" scoped>
$sewcase-christmas-light-blue: #0db0c9;
$sewcase-christmas-teal: #1dcfc6;
$sewcase-christmas-pink: #d15ca4;
$sewcase-christmas-light-pink: #db83a2;
$sewcase-christmas-light-green: #51e449;
$sewcase-christmas-dark-green: #60ca70;

.advent-calendar {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: $spacing-sm;

  @media (min-width: $md) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: $xl) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.door {
  aspect-ratio: 1 / 1;
  cursor: pointer;
  background-color: #fff;
  background-size: 96px, cover;
  background-position: bottom right, center;
  background-repeat: no-repeat;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  transition: all 0.3s ease;

  @media (min-width: $md) {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0);

    &:hover {
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
      transform: scale(1.02);
    }
  }

  &.door-1 {
    background-image: url('../assets/images/christmas/inverted_candy-cane.png'),
      linear-gradient(
        to bottom,
        $sewcase-christmas-light-blue 0%,
        $sewcase-christmas-teal 100%
      );
    background-position: calc(100% + 16px) calc(100% - 8px), center;
  }

  &.door-2 {
    background-image: url('../assets/images/christmas/inverted_christmas-balls.png'),
      linear-gradient(
        to bottom,
        $sewcase-christmas-pink 0%,
        $sewcase-christmas-light-pink 100%
      );
    background-position: top left, center;
  }

  &.door-3 {
    background-image: url('../assets/images/christmas/inverted_gift.png'),
      linear-gradient(
        to bottom,
        $sewcase-christmas-dark-green 0%,
        $sewcase-christmas-light-green 100%
      );
    background-position: bottom left, center;
  }

  &.door-4 {
    background-image: url('../assets/images/christmas/inverted_lights.png'),
      linear-gradient(
        to bottom,
        $sewcase-christmas-light-blue 0%,
        $sewcase-christmas-teal 100%
      );
    background-size: 120px, cover;
    background-position: top center, center;
  }

  &.door-5 {
    background-image: url('../assets/images/christmas/inverted_snowflake.png'),
      linear-gradient(
        to bottom,
        $sewcase-christmas-dark-green 0%,
        $sewcase-christmas-light-green 100%
      );
    background-position: top right, center;
  }

  &.door-6 {
    background-image: url('../assets/images/christmas/inverted_gift_2.png'),
      linear-gradient(
        to bottom,
        $sewcase-christmas-pink 0%,
        $sewcase-christmas-light-pink 100%
      );
  }

  &.door-7 {
    background-image: url('../assets/images/christmas/inverted_stars.png'),
      linear-gradient(
        to bottom,
        $sewcase-christmas-pink 0%,
        $sewcase-christmas-light-pink 100%
      );
    background-position: top left, center;
    background-size: 80px, cover;
  }

  &.door-8 {
    background-image: url('../assets/images/christmas/inverted_christmas-tree.png'),
      linear-gradient(
        to bottom,
        $sewcase-christmas-light-blue 0%,
        $sewcase-christmas-teal 100%
      );
    background-position: bottom left, center;
  }

  &.door-9 {
    background-image: url('../assets/images/christmas/inverted_snowman.png'),
      linear-gradient(
        to bottom,
        $sewcase-christmas-dark-green 0%,
        $sewcase-christmas-light-green 100%
      );
    background-position: bottom left, center;
  }
}

.door-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.door-number {
  font-size: 24px;
  font-weight: bold;
  color: #000;
}

.door-today {
  border: 4px dashed $sewcase-nb-red;
}

.door-prevent {
  animation: appear 0.3s ease;
  position: relative;

  img {
    width: 112px;
  }

  .speech-bubble {
    background-color: white;
    border: 2px solid $sewcase-black;
    padding: 4px 8px;
    border-radius: 12px 12px 0;
    position: absolute;
    top: -$spacing-lg;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    white-space: nowrap;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal-content {
  aspect-ratio: 3 / 5;
  animation: appear 1s ease;
  position: relative;
  background-color: $sewcase-white;
  border-radius: 6px;
  max-height: 90dvh;
  max-width: $sm;
  width: 96%;
  overflow: hidden;

  @media (min-width: $md) {
    width: 90%;
  }
}

.modal-background {
  background-size: cover;
  background-position: center;
  color: #fff;
  display: flex;
  height: 100%;
  padding: $spacing-md;
  position: relative;
}

.advent-info {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 4px;
  margin-top: auto;
  padding: $spacing-sm $spacing-md;
  width: 100%;

  .advent-social {
    display: grid;
    grid-template-columns: 24px 1fr;
    align-items: center;
    gap: $spacing-sm;
    margin-bottom: $spacing-sm;
  }

  .advent-text {
    padding-left: 8px;
    border-left: 4px solid $sewcase-black;
  }

  h2 {
    color: $sewcase-text-dark;
    margin: 0 0 $spacing-md;
    text-shadow: 3px 3px $sewcase-white;
  }
  a {
    text-decoration: none;
    font-weight: bold;

    &:hover {
      color: $sewcase-secondary;
    }
  }
  p {
    color: $sewcase-text-dark;
    margin-bottom: $spacing-sm;
  }
  img {
    width: 21px;
  }
}

.close-button {
  background: rgba(10, 10, 10, 0.7);
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    color: $sewcase-secondary;
    transform: rotate(180deg);
  }
}
</style>
