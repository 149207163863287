<template>
  <Navigation />
  <router-view v-slot="{ Component }" :key="$route.path">
    <transition name="fade-slide-up" mode="out-in" appear>
      <component :is="Component" />
    </transition>
  </router-view>
  <BubbleButton />
</template>

<script>
/* eslint-disable */
import { mapMutations } from 'vuex';
import Navigation from './views/Navigation';
import BubbleButton from './components/BubbleButton.vue';
const contentful = require('contentful');

export default {
  name: 'vcp-app',
  components: {
    Navigation,
    BubbleButton,
  },
  data() {
    return {
      pages: [],
      socialMedia: [],
    };
  },
  async created() {
    await this.setupContentfulPages();
  },
  methods: {
    ...mapMutations([
      'setPagesEN',
      'setRoutesEN',
      'setPagesDE',
      'setRoutesDE',
      'setSocialMedia',
      'setChristmasFeatureList',
    ]),

    setupContentfulPages() {
      var client = contentful.createClient({
        space: process.env.VUE_APP_CONTENTFUL_SPACE_ID,
        accessToken: process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN,
      });

      client
        .getEntries({ content_type: 'globalSocialMedia' })
        .then((response) => {
          this.setSocialMedia(response.items);
        });

      client
        .getEntries({ content_type: 'cosplayerFeatureWrapper' })
        .then((response) => {
          const filteredItem = response.items.find(
            (item) => item.fields.id === 'Christmas_Calendar_2024'
          );

          if (filteredItem) {
            const processedReferences = filteredItem.fields?.reference.map(
              (refItem) => {
                const { sys, metadata, ...rest } = refItem;
                return rest;
              }
            );

            const sortedReferences = processedReferences.sort((a, b) => {
              return a.fields.id.localeCompare(b.fields.id, undefined, {
                numeric: true,
              });
            });

            this.setChristmasFeatureList(sortedReferences);
          } else {
            console.log('No matching item found');
          }
        });

      client
        .getEntries({ include: 3, content_type: 'page', locale: 'de-DE' })
        .then((response) => {
          var pageComponents = response.items;

          var allRoutes = pageComponents.map((page) => {
            if (page?.fields?.slug) {
              return {
                slug: page.fields.slug,
                name: page.fields.pageName,
                url: page.fields.slug !== '/' ? '/' + page.fields.slug : '/',
                hide: page.fields.hideFromNavigation ?? false,
                position: page.fields.position ?? undefined,
                subPaths: [],
                title: page.fields.title ?? undefined,
              };
            }
          });

          allRoutes = allRoutes.filter(function (page) {
            return page !== undefined;
          });

          //step 1: eine liste mit den subPaths
          var subPathList = [];
          allRoutes.forEach((item) => {
            var splittedSubItem = item.url.split('/');

            // e.g. ["","path","subpath"]
            if (splittedSubItem.length > 2) {
              subPathList.push({ url: item.url, title: item.title });
            }
          });

          //step 2: die neue liste gegen "before" halten, filtern und eine cleanList erstellen
          var cleanList = this.filterRoutes(allRoutes, subPathList);

          //step 3: suche in "before" nach den ersten part vom path und wenn er da ist,
          //dann in subPaths hinzufügen
          subPathList.forEach((subPathItem) => {
            var splittedSubItem = subPathItem.url.split('/');
            splittedSubItem = '/' + splittedSubItem[1];

            cleanList.forEach((cleanItem) => {
              if (cleanItem.url == splittedSubItem) {
                cleanItem.subPaths.push({
                  url: subPathItem.url,
                  title: subPathItem.title,
                });
              }
            });
          });

          this.setPagesDE(response.items);
          this.setRoutesDE(cleanList);
        });

      client
        .getEntries({ include: 3, content_type: 'page', locale: 'en-US' })
        .then((response) => {
          var pageComponents = response.items;

          var allRoutes = pageComponents.map((page) => {
            if (page?.fields?.slug) {
              return {
                slug: page.fields.slug,
                name: page.fields.pageName,
                url: page.fields.slug !== '/' ? '/' + page.fields.slug : '/',
                hide: page.fields.hideFromNavigation ?? false,
                position: page.fields.position ?? undefined,
                subPaths: [],
                title: page.fields.title ?? undefined,
              };
            }
          });

          allRoutes = allRoutes.filter(function (page) {
            return page !== undefined;
          });

          //step 1: eine liste mit den subPaths
          var subPathList = [];
          allRoutes.forEach((item) => {
            var splittedSubItem = item.url.split('/');

            // e.g. ["","path","subpath"]
            if (splittedSubItem.length > 2) {
              subPathList.push({ url: item.url, title: item.title });
            }
          });

          //step 2: die neue liste gegen "before" halten, filtern und eine cleanList erstellen
          var cleanList = this.filterRoutes(allRoutes, subPathList);

          //step 3: suche in "before" nach den ersten part vom path und wenn er da ist,
          //dann in subPaths hinzufügen
          subPathList.forEach((subPathItem) => {
            var splittedSubItem = subPathItem.url.split('/');
            splittedSubItem = '/' + splittedSubItem[1];

            cleanList.forEach((cleanItem) => {
              if (cleanItem.url == splittedSubItem) {
                cleanItem.subPaths.push({
                  url: subPathItem.url,
                  title: subPathItem.title,
                });
              }
            });
          });

          this.setPagesEN(response.items);
          this.setRoutesEN(cleanList);
        });
    },
    filterRoutes(allRoutes, subPathList) {
      return allRoutes.filter((item) => {
        return !subPathList.find((a) => a.url == item.url);
      });
    },
  },
};
</script>

<style lang="scss">
.fade-slide-up-enter-active,
.fade-slide-up-leave-active {
  transition: opacity 1.2s ease;
}

.fade-slide-up-enter-from,
.fade-slide-up-leave-to {
  opacity: 0.8;
}

.fade-slide-up-enter-to,
.fade-slide-up-leave-from {
  opacity: 1;
}
</style>
