<template>
  <div class="nav-desktop">
    <div
      v-for="(route, index) in sortRoutes()"
      :key="index"
      class="nav-item"
      :class="{ hide: route.hide }"
    >
      <router-link v-if="!route.hide" :to="route.url" :key="index">
        {{ sanitizeRouterLabel(route.title) }}
      </router-link>

      <div class="nav-sub-item-container" v-if="route.subPaths.length > 0">
        <router-link
          v-for="(subNavItem, index) in route.subPaths"
          class="nav-item"
          :to="subNavItem.url"
          :key="index"
        >
          {{ sanitizeRouterLabel(subNavItem.title) }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'navigationDesktop',
  computed: {
    ...mapGetters(['getRoutesEN', 'getRoutesDE', 'getLanguage']),
  },
  methods: {
    ...mapMutations(['setLanguage']),
    sortRoutes() {
      const routes =
        this.getLanguage == 'de-DE' ? this.getRoutesDE : this.getRoutesEN;

      var sortedList = routes
        .filter((route) => route.position !== undefined)
        .sort((a, b) => a.position - b.position);

      return sortedList;
    },
    sanitizeRouterLabel(route) {
      if (route === '/' || route === undefined || route == '')
        route = 'About Us';

      route = route.replace('-', ' ');

      route = route[0].toUpperCase() + route.substring(1);

      return route;
    },
    toggleBurgerMenu() {
      var navBar = document.getElementById('nav-mobile-bar');
      var navIcon = document.getElementById('nav-mobile-icon');
      navBar.classList.toggle('nav-mobile-bar--open');
      navIcon.classList.toggle('nav-mobile-icon--open');
    },
    switchLanguage(lang) {
      this.setLanguage(lang);
    },
  },
};
</script>

<style lang="scss">
.nav {
  &-desktop {
    display: none;

    @media (min-width: $md) {
      display: flex;
      align-items: center;
    }
  }

  &-item {
    display: flex;
    align-items: center;
    font-size: $font-lg;
    padding: $spacing-md 0;
    margin: 0 $spacing-md * 0.75;
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      bottom: $spacing-md;
      width: 0;
      height: 12px;
      background: $sewcase-primary;
      opacity: 0.5;
      transition: $transition-width-ease;
      z-index: -1;
    }

    &:hover {
      &:before {
        width: 100%;
      }

      .nav-sub-item-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        div {
          margin: $spacing-sm 0;
        }
      }
    }

    &.hide {
      display: none;
    }
  }

  &-sub-item-container {
    background-color: $sewcase-white;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2);
    display: none;
    position: absolute;
    left: 50%;
    top: calc($spacing-lg * 1.5);
    transform: translateX(-50%);
    text-align: center;
    margin-top: $spacing-sm;
    min-width: 120px;
    padding: $spacing-sm;
    white-space: nowrap;
    z-index: 11;

    &::before,
    ::after {
      content: '';
      width: 0px;
      height: 0px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    &::before {
      border-bottom: 12px solid $sewcase-white;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      top: -10px;
    }

    > a {
      padding: 12px 0;
    }

    .nav-item {
      margin: 0;
    }
  }
}
</style>
