<template>
  <div class="richtext-wrapper" :id="id || ''">
    <RichTextRenderer :document="content" />
  </div>
</template>

<script>
import RichTextRenderer from "contentful-rich-text-vue-renderer";

export default {
  name: "RichText",
  props: {
    content: [Array, Object],
    id: String,
  },
  components: {
    RichTextRenderer,
  },
};
</script>

<style lang="scss">
.richtext-wrapper {
  margin-bottom: 16px;
  padding-bottom: 8px;

  h1,
  h2 {
    margin-bottom: 16px;
  }

  h6 {
    font-size: 16px;
  }
}
</style>
