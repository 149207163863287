<template>
  <div class="nav-mobile">
    <div
      id="nav-mobile-icon"
      class="nav-mobile-icon"
      v-on:click="toggleBurgerMenu"
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>

    <div id="nav-mobile-bar" class="nav-mobile-bar">
      <div class="nav-mobile-list">
        <div
          class="nav-mobile-link"
          v-for="(route, index) in sortRoutes()"
          :key="index"
        >
          <router-link
            v-if="!route.hide"
            :to="route.url"
            v-on:click="toggleBurgerMenu"
          >
            {{ sanitizeRouterLabel(route.title) }}
          </router-link>

          <template v-if="route.subPaths.length > 0">
            <router-link
              v-for="(subNavItem, index) in route.subPaths"
              v-on:click="toggleBurgerMenu"
              :to="subNavItem.url"
              :key="index"
            >
              <span class="nav-mobile-arrow nav-mobile-arrow--right"></span>
              {{ sanitizeRouterLabel(subNavItem.title) }}
            </router-link>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "navigationMobile",
  computed: {
    ...mapGetters(["getRoutesEN", "getRoutesDE", "getLanguage"]),
  },
  methods: {
    ...mapMutations(["setLanguage"]),
    sortRoutes() {
      const routes =
        this.getLanguage == "de-DE" ? this.getRoutesDE : this.getRoutesEN;

      var sortedList = routes.sort(function (a, b) {
        return a.position - b.position || a.name?.localeCompare(b.name);
      });

      return sortedList;
    },
    sanitizeRouterLabel(route) {
      if (route === "/" || route === undefined || route == "")
        route = "About Us";

      route = route.replace("-", " ");

      route = route[0].toUpperCase() + route.substring(1);

      return route;
    },
    toggleBurgerMenu() {
      var navBar = document.getElementById("nav-mobile-bar");
      var navIcon = document.getElementById("nav-mobile-icon");
      navBar.classList.toggle("nav-mobile-bar--open");
      navIcon.classList.toggle("nav-mobile-icon--open");
    },
    switchLanguage(lang) {
      this.setLanguage(lang);
    },
  },
};
</script>

<style lang="scss">
.nav {
  &-mobile {
    display: flex;
    width: 60px;

    @media (min-width: $md) {
      display: none;
    }

    &-arrow {
      border: solid $sewcase-white;
      border-width: 0 3px 3px 0;
      display: inline-block;
      margin: 2px;
      padding: 4px;

      @media (min-width: $md) {
        display: none;
      }

      &--right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }

    &-icon {
      align-self: center;
      cursor: pointer;
      height: 40px;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      transition: $transition-all-ease;
      width: 60px;
      z-index: 11;

      span {
        background: $sewcase-black;
        border-radius: 7px;
        display: block;
        left: 0;
        height: 5px;
        width: 100%;
        opacity: 1;
        position: fixed;
        transform: rotate(0deg);
        transition: 0.33s ease;

        &:first-child {
          top: 0;
        }
        &:nth-child(2),
        &:nth-child(3) {
          top: 18px;
        }
        &:last-child {
          top: 36px;
        }
      }

      &--open {
        position: fixed;
        top: $spacing-md * 1.75;
        right: $spacing-md * 1.5;

        span {
          background: $sewcase-white;
          border-bottom: 0px;
          border-right: 0px;

          &:first-child {
            top: 18px;
            transform: rotate(45deg);
            opacity: 0;
          }
          &:nth-child(2) {
            transform: rotate(-45deg);
          }
          &:nth-child(3) {
            transform: rotate(45deg);
          }
          &:last-child {
            top: 18px;
            transform: rotate(-45deg);
            opacity: 0;
          }
        }
      }
    }

    &-bar {
      align-items: center;
      background: rgba(17, 17, 17, 0.9);
      height: 100%;
      justify-content: center;
      left: 0;
      margin: 0;
      padding: 0;
      position: fixed;
      top: 0;
      transform: translateX(-100%);
      transition: $transition-all-ease;
      width: 100%;
      z-index: 10;

      &--open {
        transform: translateX(0);
      }
    }
    &-list {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: $spacing-xl * 1.5 $spacing-xl $spacing-xl;
    }

    &-link {
      font-size: $font-md;

      &:not(:last-child) {
        margin-bottom: $spacing-md;
      }

      a {
        color: $sewcase-text-light;
        display: block;
        font-size: $font-xl;
        margin-top: $spacing-md;
        transition: $transition-all-ease;
      }
    }

    &-multi {
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        margin-bottom: $spacing-md;
        &:first-child {
          margin-top: $spacing-md;
        }
      }

      &-icon {
        filter: invert(1);
        width: 16px;
        transform: scaleY(-1) rotate(90deg);
      }
    }
  }
}
</style>
