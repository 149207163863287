<template>
  <div v-if="components?.length > 0">
    <section
      v-for="(component, index) in components"
      :key="index"
      :class="[
        'component-section',
        {
          'component-section--content':
            component.sys.contentType.sys.id !== 'heroImage',
        },
      ]"
    >
      <HeroImage
        v-if="component.sys.contentType.sys.id == 'heroImage'"
        :content="component?.fields"
      />
      <RichText
        v-if="component.sys.contentType.sys.id == 'richtext'"
        :content="component?.fields?.content"
        :id="component?.fields?.id"
      />
      <AccordionItem
        v-if="component.sys.contentType.sys.id == 'accordionItem'"
        :content="component?.fields"
      />
      <TextImage
        v-if="component.sys.contentType.sys.id == 'textImage'"
        :content="component?.fields"
      />
      <TeaserContainer
        v-if="component.sys.contentType.sys.id == 'teaserContainer'"
        :content="component?.fields"
      />
      <SocialMedia
        v-if="component.sys.contentType.sys.id == 'socialMedia'"
        :content="component?.fields.reference"
      />
      <ImageContainer
        v-if="component.sys.contentType.sys.id == 'image'"
        :content="component?.fields"
      />
      <NeubrutalismCardWrapper
        v-if="component.sys.contentType.sys.id == 'nbCardWrapper'"
        :content="component?.fields"
      />
      <RegisterSection
        v-if="component.sys.contentType.sys.id == 'contestRegister'"
        :content="component?.fields"
      />
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import RichText from "./Richtext";
import AccordionItem from "./AccordionItem";
import HeroImage from "./HeroImage";
import ImageContainer from "./ImageContainer";
import SocialMedia from "./SocialMedia";
import TextImage from "./TextImage";
import TeaserContainer from "./TeaserContainer";
import NeubrutalismCardWrapper from './NeubrutalismCardWrapper'
import RegisterSection from './RegisterSection'

export default {
  components: {
    RichText,
    AccordionItem,
    HeroImage,
    ImageContainer,
    TextImage,
    TeaserContainer,
    SocialMedia,
    NeubrutalismCardWrapper,
    RegisterSection
  },
  props: {
    components: Array,
  },
};
</script>