<template>
  <div class="register-section" id="registration">
    <div class="register-card">
      <div class="register-heart">
        <div class="pixel-heart"></div>
      </div>

      <div class="register-content">
        Vielen Dank für die Einsendungen!
        <br />
        <br />
        <h4>
          Wir schicken die zusagen den Teilnehmern zu und werden euch auf
          unseren Social Media Kanälen up-to-date halten.
        </h4>
      </div>

      <div
        class="register-button-wrapper"
        @mouseover="handleMouseOver"
        @mouseleave="handleMouseLeave"
      >
        <a
          class="register-button"
          :class="{
            'register-button--disabled': !bothChecked,
          }"
        >
          Wir sehen uns auf der Dokomi
        </a>
        <span
          class="register-button--shadow"
          :class="{
            'register-button--disabled': !bothChecked,
          }"
        >
        </span>
      </div>
    </div>

    <div class="richtext-wrapper">
      <RichTextRenderer :document="content.afterContent" />
    </div>
  </div>
</template>

<script>
import RichTextRenderer from "contentful-rich-text-vue-renderer";

export default {
  name: "cl-register-section",
  components: {
    RichTextRenderer,
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showPoro: false,
      timeoutId: null,
      isChecked: false,
      readTos: false,
      readDP: false,
    };
  },
  computed: {
    bothChecked() {
      return this.readTos && this.readDP;
    },
    showPoroComputed() {
      return this.showPoro;
    },
  },
  methods: {
    handleMouseOver() {
      clearTimeout(this.timeoutId);

      this.timeoutId = setTimeout(() => {
        this.showPoro = true;
      }, 1000);
    },
    handleMouseLeave() {
      clearTimeout(this.timeoutId);

      this.showPoro = false;
    },
    clickHandler() {
      if (this.bothChecked && this.content.buttonUrl?.length) {
        window.open(this.content.buttonUrl, "_blank");
      }
    },
  },
};
</script>

<style lang="scss">
.register-section {
  .register-card {
    border: 3px solid $sewcase-black;
    border-radius: 8px;
    background: $sewcase-nb-rose;
    padding: 24px 36px;
    height: calc(100% - 32px);
    width: calc(100% - 32px);
    max-width: 475px;
    margin: 0 auto 42px;
    margin-left: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (min-width: $sm) {
      margin-left: auto;
    }

    &:before {
      content: "";
      position: absolute;
      border-radius: 8px;
      right: -12px;
      bottom: -12px;
      width: calc(100%);
      height: calc(100%);
      background: $sewcase-black;
      z-index: -1;
    }
  }

  .register-button-wrapper {
    position: relative;

    &:hover {
      .register-button {
        top: 4px;
        left: 4px;

        &.register-button--disabled {
          top: 0 !important;
          left: 0 !important;
        }
      }

      .register-button--shadow {
        right: -4px;
        bottom: -4px;

        &.register-button--disabled {
          right: -8px !important;
          bottom: -8px !important;
        }
      }
    }
  }

  .register-content {
    font-size: 24px;
  }

  .register-button {
    cursor: pointer;
    border: 3px solid $sewcase-black;
    border-radius: 8px;
    background: $sewcase-nb-gold;
    display: block;
    padding: 16px 32px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0;
    text-align: center;
    z-index: 1;
    transition: all 0.2s ease;

    &.register-button--disabled {
      cursor: not-allowed;
      background: $sewcase-grey;
    }
  }

  .register-button--shadow {
    content: "";
    background: black;
    width: 100%;
    position: absolute;
    height: 100%;
    padding: 24px;
    right: -8px;
    bottom: -8px;
    border-radius: 8px;
    z-index: 0;
    transition: all 0.2s ease;
  }

  .register-heart {
    border-radius: 8px;
    position: absolute;
    top: -30px;
    right: -30px;
    height: 60px;
    width: 65px;
    background: $sewcase-nb-gold;
    padding: 20px;
    border: 3px solid $sewcase-black;

    &:before {
      content: "";
      position: absolute;
      border-radius: 8px;
      right: -8px;
      bottom: -8px;
      width: 100%;
      height: 100%;
      background: $sewcase-black;
      z-index: -1;
    }
  }

  .pixel-heart {
    width: 20px;
    height: 20px;
    background-color: red;
    position: relative;
    transform: rotate(-45deg);
    top: 4px;
  }

  .pixel-heart:before,
  .pixel-heart:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: red;
  }

  .pixel-heart:before {
    top: -20px;
    left: 0;
  }

  .pixel-heart:after {
    top: 0;
    left: 20px;
  }

  .pixel-heart .left-half,
  .pixel-heart .right-half {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: red;
  }

  .pixel-heart .left-half {
    top: 0;
    left: 0;
  }

  .pixel-heart .right-half {
    top: 0;
    left: 10px;
  }

  .pixel-heart .bottom-half {
    position: absolute;
    width: 20px;
    height: 10px;
    background-color: red;
    top: 10px;
    left: 0;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.custom-checkbox {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 14px;
  color: #333; /* Dark gray label text color */

  p {
    margin: 0;
  }
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: $sewcase-white;
  border: 3px solid $sewcase-black;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: $sewcase-nb-red;
}
</style>
