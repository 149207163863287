<template>
  <ComponentLoader class="contest-page" :components="getSpecificPage" />
</template>

<script>
import { mapGetters } from "vuex";
import ComponentLoader from "../components/ComponentLoader.vue";

export default {
  name: "contest-page",
  components: {
    ComponentLoader,
  },
  computed: {
    ...mapGetters(["getPagesEN", "getPagesDE", "getLanguage"]),

    getSpecificPage() {
      const page =
        this.getLanguage == "de-DE" ? this.getPagesDE : this.getPagesEN;

      const specificPage = page.find((page) => {
        const pageSlug =
          page.fields.slug !== "/" ? "/" + page.fields.slug : "/";
        return pageSlug == window.location.pathname;
      });

      return specificPage?.fields?.reference;
    },
  },
  mounted() {
    this.setupStyles();
  },
  methods: {
    setupStyles() {
      this.styleElement = document.createElement("style");
      this.styleElement.textContent = `
        body { 
          background-color: #f8e5ff; 
        }
        nav {
          background-color: #f8e5ff;
        }
      `;
      this.styleElement.id = "contest-style";

      document.head.appendChild(this.styleElement);
    },
  },
  beforeUnmount() {
    const styleElement = document.getElementById("contest-style");
    if (styleElement && styleElement.parentNode) {
      styleElement.parentNode.removeChild(styleElement);
    }
  },
};
</script>

<style lang="scss">
.contest-page {
  hr {
    border-color: #000000;
    border-width: 2px;
    margin: 32px 0;
  }
  p {
    line-height: 1.3;
  }

  .accordion__container {
    border-radius: 8px;
    border-width: 3px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      background: $sewcase-black;
      border-radius: $border-radius-sm;
      right: -20px;
      bottom: -20px;
      width: 100%;
      height: 100%;
      transition: all 0.2s ease;
      z-index: -1;
    }

    .accordion__headline {
      background: $sewcase-nb-rose;
      border-radius: 4px 4px 0 0;
      border-bottom: 3px solid $sewcase-black;
    }
    .accordion__content {
      background: $sewcase-nb-rose;
      border-radius: 0 0 4px 4px;
      * {
        color: $sewcase-black;
      }
      a {
        color: $sewcase-secondary;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
