<template>
  <div className="accordion__container" :id="content.id || ''">
    <div class="accordion__headline" @click="toggleContainer">
      {{ content.title }}
      <div class="accordion__toggle" />
    </div>
    <div class="accordion__content">
      <RichTextRenderer :document="content.content" />
    </div>
  </div>
</template>

<script>
import RichTextRenderer from "contentful-rich-text-vue-renderer";

export default {
  name: "AccordionItem",
  props: {
    content: [Array, Object],
  },
  components: {
    RichTextRenderer,
  },
  methods: {
    toggleContainer() {
      var padding = 32;
      var currentItem = this.$el.querySelector(".accordion__content");
      var currentHeight = currentItem.offsetHeight;
      var actualHeight = currentItem.scrollHeight;

      if (currentHeight <= padding) {
        currentItem.style.height = actualHeight + "px";
      } else {
        currentItem.style.height = 0;
      }

      this.$el
        .querySelector(".accordion__toggle")
        .classList.toggle("accordion__toggle--open");
    },
  },
};
</script>

<style lang="scss">
.accordion {
  &__content {
    height: 0;
    background: $sewcase-black;
    padding: 16px 32px;
    overflow: hidden;
    text-align: left;
    transition: all 0.4s cubic-bezier(0.26, 1, 0.78, 1);

    * {
      color: $sewcase-white;
    }

    a {
      color: $sewcase-secondary;

      &:hover {
        color: $sewcase-secondary-light;
      }
    }
  }

  &__container {
    border: 2px solid $sewcase-black;
    margin-bottom: 32px;
  }

  &__headline {
    background: $sewcase-white;
    cursor: pointer;
    font-size: $font-lg;
    padding: $spacing-md;
    padding-right: $spacing-lg;
    position: relative;
    user-select: none;
    text-align: left;

    @media (min-width: $lg) {
      padding-right: $spacing-lg + $spacing-md;
    }
  }

  &__toggle {
    &:before,
    &:after {
      content: "";
      background: $sewcase-black;
      position: absolute;
      top: 50%;
      transition: all 0.3s ease;
      transform: translateY(-50%);
    }

    &:before {
      width: 20px;
      height: 4px;
      right: 12px;
    }
    &:after {
      height: 20px;
      width: 4px;
      right: 20px;
    }

    &--open {
      &:before {
        transform: translateY(-50%) rotate(180deg);
      }
      &:after {
        transform: translateY(-50%) rotate(270deg);
      }
    }
  }
}
</style>
