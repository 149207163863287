<template>
  <div class="component-section component-section--content">
    <template v-if="this.getLanguage == 'de-DE'">
      <h2>Vielen Dank für deine Zeit und Geduld.</h2>
      <p>
        Wir hoffen dich bald wieder auf einer Convention anzutreffen. Sollte es
        Probleme mit den Bildern geben, so kontaktiere uns doch bitte per Mail
        unter <a href="mailto:info@sewcase.de">info@sewcase.de</a> und gib uns
        als Info den Link aus der URL mit.
      </p>
    </template>
    <template v-else>
      <h2>Thank you for your time and patience.</h2>
      <p>
        We hope to see you again soon at a convention. Should there be any
        problems with the pictures, please contact us on our social media pages
        or through
        <a href="mailto:info@sewcase.de">info@sewcase.de</a> with this URL.
      </p>
    </template>
    <swiper
      v-if="parsedFiles"
      :effect="'coverflow'"
      grabCursor
      slideToClickedSlide
      centeredSlides
      :navigation="true"
      :pagination="{ type: 'fraction' }"
      :slidesPerView="'auto'"
      :coverflowEffect="{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
      }"
      :modules="modules"
      :breakpoints="{
        320: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        769: {
          slidesPerView: 2,
          spaceBetween: 32,
        },
      }"
    >
      <template v-for="(item, index) in filesList" :key="index">
        <swiper-slide>
          <img
            :src="`https://sewcase.org/root/${this.pathnames[2]}/${this.pathnames[3]}/${item}_small.jpg`"
          />
          <div
            class="swiper-image-download"
            @click="
              redirectToImage(
                `https://sewcase.org/root/${this.pathnames[2]}/${this.pathnames[3]}/${item}.jpg`
              )
            "
          >
            Click here to download this image in High Quality
          </div>
        </swiper-slide>
      </template>
    </swiper>
  </div>
</template>

<script>
/* eslint-disable */
import { Swiper, SwiperSlide } from 'swiper/vue';
import { mapGetters } from 'vuex';
import { Navigation, Pagination, EffectCoverflow } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';

export default {
  name: 'image-page',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      files: 0,
      filesList: [],
      pathnames: '',
      info: [],
    };
  },
  setup() {
    return {
      modules: [Navigation, Pagination, EffectCoverflow],
    };
  },
  async created() {
    this.pathnames = window.location.pathname;
    this.pathnames = this.pathnames.split('/');
    const fetchUrl = `https://sewcase.org/root/${this.pathnames[2]}/${this.pathnames[3]}/files.txt`;

    await fetch(fetchUrl)
      .then((response) => response.text())
      .then((data) => {
        this.files = data;
      })
      .catch(() => {
        return "couldn't find any images... please contact us.";
      });

    const fetchUrlFile = `https://sewcase.org/root/${this.pathnames[2]}/${
      this.pathnames[3]
    }/${this.md5(this.pathnames[3])}.txt`;

    await fetch(fetchUrlFile)
      .then((response) => response.text())
      .then((data) => {
        const arr = data.toString().replace(/\r\n/g, '\n').split('\n');

        for (let i of arr) {
          this.info.push(i);
        }
      })
      .catch(() => {
        return "couldn't find any images... please contact us.";
      });

    await this.getFilesImageNumbers();
  },
  mounted() {
    this.files = this.parsedFiles;
  },
  computed: {
    ...mapGetters(['getLanguage']),

    isPostable() {
      return this.info.indexOf('postable: Yes') > -1;
    },

    parsedFiles() {
      return parseInt(this.files);
    },
  },
  methods: {
    async getFilesImageNumbers() {
      for (var index = 0; index < this.parsedFiles; index++) {
        await fetch(
          `https://sewcase.org/root/${this.pathnames[2]}/${this.pathnames[3]}/${index}_small.jpg`
        )
          .then(() => {
            this.filesList.push(index);
          })
          .catch(() => {
            console.log(`current image is not available: ${index}_small.jpg`);
          });
      }
    },
    redirectToImage(url) {
      window.open(url, '_blank');
    },
    md5(inputString) {
      var hc = '0123456789abcdef';
      function rh(n) {
        var j,
          s = '';
        for (j = 0; j <= 3; j++)
          s +=
            hc.charAt((n >> (j * 8 + 4)) & 0x0f) +
            hc.charAt((n >> (j * 8)) & 0x0f);
        return s;
      }
      function ad(x, y) {
        var l = (x & 0xffff) + (y & 0xffff);
        var m = (x >> 16) + (y >> 16) + (l >> 16);
        return (m << 16) | (l & 0xffff);
      }
      function rl(n, c) {
        return (n << c) | (n >>> (32 - c));
      }
      function cm(q, a, b, x, s, t) {
        return ad(rl(ad(ad(a, q), ad(x, t)), s), b);
      }
      function ff(a, b, c, d, x, s, t) {
        return cm((b & c) | (~b & d), a, b, x, s, t);
      }
      function gg(a, b, c, d, x, s, t) {
        return cm((b & d) | (c & ~d), a, b, x, s, t);
      }
      function hh(a, b, c, d, x, s, t) {
        return cm(b ^ c ^ d, a, b, x, s, t);
      }
      function ii(a, b, c, d, x, s, t) {
        return cm(c ^ (b | ~d), a, b, x, s, t);
      }
      function sb(x) {
        var i;
        var nblk = ((x.length + 8) >> 6) + 1;
        var blks = new Array(nblk * 16);
        for (i = 0; i < nblk * 16; i++) blks[i] = 0;
        for (i = 0; i < x.length; i++)
          blks[i >> 2] |= x.charCodeAt(i) << ((i % 4) * 8);
        blks[i >> 2] |= 0x80 << ((i % 4) * 8);
        blks[nblk * 16 - 2] = x.length * 8;
        return blks;
      }
      var i,
        x = sb(inputString),
        a = 1732584193,
        b = -271733879,
        c = -1732584194,
        d = 271733878,
        olda,
        oldb,
        oldc,
        oldd;
      for (i = 0; i < x.length; i += 16) {
        olda = a;
        oldb = b;
        oldc = c;
        oldd = d;
        a = ff(a, b, c, d, x[i + 0], 7, -680876936);
        d = ff(d, a, b, c, x[i + 1], 12, -389564586);
        c = ff(c, d, a, b, x[i + 2], 17, 606105819);
        b = ff(b, c, d, a, x[i + 3], 22, -1044525330);
        a = ff(a, b, c, d, x[i + 4], 7, -176418897);
        d = ff(d, a, b, c, x[i + 5], 12, 1200080426);
        c = ff(c, d, a, b, x[i + 6], 17, -1473231341);
        b = ff(b, c, d, a, x[i + 7], 22, -45705983);
        a = ff(a, b, c, d, x[i + 8], 7, 1770035416);
        d = ff(d, a, b, c, x[i + 9], 12, -1958414417);
        c = ff(c, d, a, b, x[i + 10], 17, -42063);
        b = ff(b, c, d, a, x[i + 11], 22, -1990404162);
        a = ff(a, b, c, d, x[i + 12], 7, 1804603682);
        d = ff(d, a, b, c, x[i + 13], 12, -40341101);
        c = ff(c, d, a, b, x[i + 14], 17, -1502002290);
        b = ff(b, c, d, a, x[i + 15], 22, 1236535329);
        a = gg(a, b, c, d, x[i + 1], 5, -165796510);
        d = gg(d, a, b, c, x[i + 6], 9, -1069501632);
        c = gg(c, d, a, b, x[i + 11], 14, 643717713);
        b = gg(b, c, d, a, x[i + 0], 20, -373897302);
        a = gg(a, b, c, d, x[i + 5], 5, -701558691);
        d = gg(d, a, b, c, x[i + 10], 9, 38016083);
        c = gg(c, d, a, b, x[i + 15], 14, -660478335);
        b = gg(b, c, d, a, x[i + 4], 20, -405537848);
        a = gg(a, b, c, d, x[i + 9], 5, 568446438);
        d = gg(d, a, b, c, x[i + 14], 9, -1019803690);
        c = gg(c, d, a, b, x[i + 3], 14, -187363961);
        b = gg(b, c, d, a, x[i + 8], 20, 1163531501);
        a = gg(a, b, c, d, x[i + 13], 5, -1444681467);
        d = gg(d, a, b, c, x[i + 2], 9, -51403784);
        c = gg(c, d, a, b, x[i + 7], 14, 1735328473);
        b = gg(b, c, d, a, x[i + 12], 20, -1926607734);
        a = hh(a, b, c, d, x[i + 5], 4, -378558);
        d = hh(d, a, b, c, x[i + 8], 11, -2022574463);
        c = hh(c, d, a, b, x[i + 11], 16, 1839030562);
        b = hh(b, c, d, a, x[i + 14], 23, -35309556);
        a = hh(a, b, c, d, x[i + 1], 4, -1530992060);
        d = hh(d, a, b, c, x[i + 4], 11, 1272893353);
        c = hh(c, d, a, b, x[i + 7], 16, -155497632);
        b = hh(b, c, d, a, x[i + 10], 23, -1094730640);
        a = hh(a, b, c, d, x[i + 13], 4, 681279174);
        d = hh(d, a, b, c, x[i + 0], 11, -358537222);
        c = hh(c, d, a, b, x[i + 3], 16, -722521979);
        b = hh(b, c, d, a, x[i + 6], 23, 76029189);
        a = hh(a, b, c, d, x[i + 9], 4, -640364487);
        d = hh(d, a, b, c, x[i + 12], 11, -421815835);
        c = hh(c, d, a, b, x[i + 15], 16, 530742520);
        b = hh(b, c, d, a, x[i + 2], 23, -995338651);
        a = ii(a, b, c, d, x[i + 0], 6, -198630844);
        d = ii(d, a, b, c, x[i + 7], 10, 1126891415);
        c = ii(c, d, a, b, x[i + 14], 15, -1416354905);
        b = ii(b, c, d, a, x[i + 5], 21, -57434055);
        a = ii(a, b, c, d, x[i + 12], 6, 1700485571);
        d = ii(d, a, b, c, x[i + 3], 10, -1894986606);
        c = ii(c, d, a, b, x[i + 10], 15, -1051523);
        b = ii(b, c, d, a, x[i + 1], 21, -2054922799);
        a = ii(a, b, c, d, x[i + 8], 6, 1873313359);
        d = ii(d, a, b, c, x[i + 15], 10, -30611744);
        c = ii(c, d, a, b, x[i + 6], 15, -1560198380);
        b = ii(b, c, d, a, x[i + 13], 21, 1309151649);
        a = ii(a, b, c, d, x[i + 4], 6, -145523070);
        d = ii(d, a, b, c, x[i + 11], 10, -1120210379);
        c = ii(c, d, a, b, x[i + 2], 15, 718787259);
        b = ii(b, c, d, a, x[i + 9], 21, -343485551);
        a = ad(a, olda);
        b = ad(b, oldb);
        c = ad(c, oldc);
        d = ad(d, oldd);
      }
      return rh(a) + rh(b) + rh(c) + rh(d);
    },
  },
};
</script>

<style lang="scss">
.swiper {
  &:before,
  &:after {
    content: '';
    width: $spacing-md;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(0.25turn, $sewcase-grey, transparent);
    z-index: 2;
  }

  &:after {
    background: linear-gradient(0.75turn, $sewcase-grey, transparent);
    left: auto;
    right: 0;
  }

  &-slide {
    user-select: none;

    img {
      width: 100%;
    }
  }

  &-pagination,
  &-image-download {
    padding-left: $spacing-md;
  }

  &-image-download {
    cursor: pointer;
    color: $sewcase-secondary;
    transition: all 0.2s ease;

    &:hover {
      color: $sewcase-secondary-dark;
    }
  }

  &-content {
    position: absolute;
    bottom: $spacing-lg;
    left: $spacing-md;
    background: rgba($sewcase-white, 0.5);
    padding: $spacing-md;
  }
}

.info-container {
  border: 3px solid $sewcase-black;
  margin: $spacing-md auto;
  width: fit-content;

  .info-head {
    background: $sewcase-black;
    border-bottom: 3px solid $sewcase-black;
    color: $sewcase-white;
    margin-bottom: $spacing-md;
    padding: $spacing-sm 0;
    text-align: center;
  }

  .info-item {
    padding: 0 $spacing-md $spacing-sm;
    text-transform: capitalize;
  }
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  color: $sewcase-secondary;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  text-shadow: 2px 0px $sewcase-black;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  text-shadow: -2px 0px $sewcase-black;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0.85;
}
</style>
