<template>
  <div class="link-tree-container">
    <div class="link-tree-image">
      <img src="../assets/images/poro_sewcase.png" alt="favicon" />
    </div>
    <div class="link-tree-description">
      {{ linkTreeDescription }}
    </div>
  </div>
  <div class="link-tree-container">
    <h4>Overview</h4>
    <a href="/gallery" class="link-item">Convention Gallery</a>
    <a href="https://www.instagram.com/summonersewcase/" class="link-item">
      Instagram
    </a>
    <!-- <h4>{{ catwalkLabel.label }}</h4>
    <a href="https://forms.gle/CT91HfcX6UrhWhq39" class="link-item">
      {{ catwalkLabel.thursday }}
      <br />
      <span>
        {{ catwalkLabel.basic }}
      </span>
    </a>
    <a href="https://forms.gle/EoMZiFXedXC1rrnD9" class="link-item">
      {{ catwalkLabel.sunday }}
      <br />
      <span>{{ catwalkLabel.league }}</span>
    </a> -->
    <h4>Cosplay Legends</h4>
    <a href="https://www.youtube.com/watch?v=8hi3U0cgBFE" class="link-item">
      {{ cosplayLegends.vod }}
    </a>
    <a href="https://www.youtube.com/watch?v=uPHLweOpiKo" class="link-item">
      {{ cosplayLegends.summary }}
    </a>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "link-tree",
  computed: {
    ...mapGetters(["getLanguage"]),

    isGerman() {
      return this.getLanguage == "de-DE";
    },

    linkTreeDescription() {
      return this.isGerman
        ? "Unser Ziel ist es, einen Ort zu schaffen, an dem wir gemeinsam eine tolle Zeit verbringen können."
        : "Our goal is to create a place where we can have a great time together";
    },

    linkTreeOverview() {
      return this.isGerman ? "Übersicht" : "Overview";
    },

    catwalkLabel() {
      return this.isGerman
        ? {
            label: "Cosplay Catwalk Anmeldung",
            thursday: "Donnerstag",
            sunday: "Sonntag",
            basic: "Allgemeiner Cosplay Catwalk",
            league:
              "League of Legends, Wild Rift, Runeterra, Valorant, TFT, Arcane",
          }
        : {
            label: "Cosplay Catwalk Registration",
            thursday: "Thursday",
            sunday: "Sunday",
            basic: "General Cosplay Catwalk",
            league:
              "League of Legends, Wild Rift, Runeterra, Valorant, TFT, Arcane",
          };
    },

    cosplayLegends() {
      return this.isGerman
        ? {
            vod: "Komplette Show",
            summary: "Riot Zusammenfassung",
          }
        : {
            vod: "Complete Show",
            summary: "Riot Summary",
          };
    },
  },
  mounted() {},
  beforeUnmount() {},
};
</script>

<style lang="scss">
.link-tree-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 375px;
  margin: 16px auto 0 auto;
  padding: 8px;

  h4 {
    margin-bottom: 0;
  }

  .link-tree-image {
    width: 100px;
    border: 2px solid $sewcase-black;
    border-radius: 50%;
    margin-bottom: 12px;

    img {
      width: 100%;
    }
  }

  .link-item {
    cursor: pointer;
    text-decoration: none;
    background: $sewcase-black;
    color: $sewcase-text-light;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 20px;
    margin: 10px;
    border: 2px solid transparent;
    border-radius: 5px;
    transition: all 0.3s ease;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    width: 100%;
    text-align: center;

    span {
      color: $sewcase-text-light;
      font-size: 12px;
      font-weight: normal;
    }
  }

  .link-item:hover {
    background-color: $sewcase-white;
    color: $sewcase-text-dark;
    border-color: $sewcase-white;
    transform: scale(1.05);
    box-shadow: 0 0 10px rgba($sewcase-black, 0.4);

    span {
      color: $sewcase-text-dark;
      font-weight: normal;
    }
  }
}
</style>
