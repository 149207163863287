/* eslint-disable */
export const wednesday = [
  //Helfer
  [
    {
      myBeginDate: '2022-08-24 08:30',
      myEndDate: '2022-08-24 12:00',
      rowLabel: 'Helfer',
      ganttBarConfig: {
        label: 'Chigo',
      },
    },
    {
      myBeginDate: '2022-08-24 12:00',
      myEndDate: '2022-08-24 15:30',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Jessica Lach',
      },
    },
    {
      myBeginDate: '2022-08-24 15:30',
      myEndDate: '2022-08-24 19:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Levi Kolb',
      },
    },
  ],
  //SPRINGER
  [
    {
      myBeginDate: '2022-08-24 08:30',
      myEndDate: '2022-08-24 12:00',
      rowLabel: 'Springer',
      ganttBarConfig: {
        label: 'TheAlcesh',
      },
    },
    {
      myBeginDate: '2022-08-24 12:00',
      myEndDate: '2022-08-24 15:30',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Ronja',
      },
    },
    {
      myBeginDate: '2022-08-24 15:30',
      myEndDate: '2022-08-24 19:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Achim',
      },
    },
  ],
  //Fotograf
  [
    {
      myBeginDate: '2022-08-24 12:00',
      myEndDate: '2022-08-24 15:30',
      rowLabel: 'Fotograf',
      ganttBarConfig: {
        label: 'Lisa Brambach',
      },
    },
  ],
  //Fotohelfer
  [
    {
      myBeginDate: '2022-08-24 12:00',
      myEndDate: '2022-08-24 15:30',
      rowLabel: 'Fotohelfer',
      ganttBarConfig: {
        label: 'Joshua Drescher',
      },
    },
  ],
  //Selfiebude
  [
    {
      myBeginDate: '2022-08-24 08:30',
      myEndDate: '2022-08-24 12:00',
      rowLabel: 'Selfiebude',
      ganttBarConfig: {
        label: 'Patrick',
      },
    },
    {
      myBeginDate: '2022-08-24 12:00',
      myEndDate: '2022-08-24 15:30',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Nicole Lach',
      },
    },
    {
      myBeginDate: '2022-08-24 15:30',
      myEndDate: '2022-08-24 19:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Victoria Hasch',
      },
    },
  ],
  //Cosplayer
  [
    {
      myBeginDate: '2022-08-24 15:30',
      myEndDate: '2022-08-24 19:00',
      rowLabel: 'Cosplayer',
      ganttBarConfig: {
        label: 'Marek ♥',
      },
    },
  ],
  [
    {
      myBeginDate: '2022-08-24 15:30',
      myEndDate: '2022-08-24 19:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Melanie',
      },
    },
  ],
  //ORGA
  [
    {
      myBeginDate: '2022-08-24 08:30',
      myEndDate: '2022-08-24 12:00',
      rowLabel: 'Orga',
      ganttBarConfig: {
        label: 'Carbiryllim',
      },
    },
    {
      myBeginDate: '2022-08-24 12:00',
      myEndDate: '2022-08-24 15:30',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Carbiryllim',
      },
    },
    {
      myBeginDate: '2022-08-24 15:30',
      myEndDate: '2022-08-24 19:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Carbiryllim',
      },
    },
  ],
];

export const thursday = [
  //Helfer 1
  [
    {
      myBeginDate: '2022-08-25 08:30',
      myEndDate: '2022-08-25 12:00',
      rowLabel: 'Helfer',
      ganttBarConfig: {
        label: 'Achim',
      },
    },
    {
      myBeginDate: '2022-08-25 12:00',
      myEndDate: '2022-08-25 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Stephan Dupont',
      },
    },
    {
      myBeginDate: '2022-08-25 16:00',
      myEndDate: '2022-08-25 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Matthias Pößnecker',
      },
    },
  ],
  //Helfer 2
  [
    {
      myBeginDate: '2022-08-25 08:30',
      myEndDate: '2022-08-25 12:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Chigo',
      },
    },
    {
      myBeginDate: '2022-08-25 12:00',
      myEndDate: '2022-08-25 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Vanni',
      },
    },
    {
      myBeginDate: '2022-08-25 16:00',
      myEndDate: '2022-08-25 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Nicole Lach',
      },
    },
  ],
  //Helfer 3
  [
    {
      myBeginDate: '2022-08-25 12:00',
      myEndDate: '2022-08-25 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Rebecca / "Vi"',
      },
    },
    {
      myBeginDate: '2022-08-25 16:00',
      myEndDate: '2022-08-25 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Chigo',
      },
    },
  ],
  //SPRINGER
  [
    {
      myBeginDate: '2022-08-25 08:30',
      myEndDate: '2022-08-25 12:00',
      rowLabel: 'Springer',
      ganttBarConfig: {
        label: 'Philip',
      },
    },
    {
      myBeginDate: '2022-08-25 12:00',
      myEndDate: '2022-08-25 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Melanie',
      },
    },
    {
      myBeginDate: '2022-08-25 16:00',
      myEndDate: '2022-08-25 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Achim',
      },
    },
  ],
  //Fotograf
  [
    {
      myBeginDate: '2022-08-25 08:30',
      myEndDate: '2022-08-25 12:00',
      rowLabel: 'Fotograf',
      ganttBarConfig: {
        label: 'Sophie',
      },
    },
    {
      myBeginDate: '2022-08-25 12:00',
      myEndDate: '2022-08-25 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Levi Kolb',
      },
    },
    {
      myBeginDate: '2022-08-25 16:00',
      myEndDate: '2022-08-25 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Alexander Friedrich',
      },
    },
  ],
  //Fotohelfer
  [
    {
      myBeginDate: '2022-08-25 08:30',
      myEndDate: '2022-08-25 12:00',
      rowLabel: 'Fotohelfer',
      ganttBarConfig: {
        label: 'Lisa Brambach',
      },
    },
    {
      myBeginDate: '2022-08-25 12:00',
      myEndDate: '2022-08-25 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Joshua Drescher',
      },
    },
    {
      myBeginDate: '2022-08-25 16:00',
      myEndDate: '2022-08-25 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Lisa Brambach',
      },
    },
  ],
  //Selfiebude
  [
    {
      myBeginDate: '2022-08-25 08:30',
      myEndDate: '2022-08-25 12:00',
      rowLabel: 'Selfiebude',
      ganttBarConfig: {
        label: 'Pooyo',
      },
    },
    {
      myBeginDate: '2022-08-25 12:00',
      myEndDate: '2022-08-25 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Alyssa',
      },
    },
    {
      myBeginDate: '2022-08-25 16:00',
      myEndDate: '2022-08-25 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Jessica Lach',
      },
    },
  ],
  //Cosplayer
  [
    {
      myBeginDate: '2022-08-25 08:30',
      myEndDate: '2022-08-25 12:00',
      rowLabel: 'Cosplayer',
      ganttBarConfig: {
        label: 'Jonas Heinz',
      },
    },
    {
      myBeginDate: '2022-08-25 12:00',
      myEndDate: '2022-08-25 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Victoria Hasch',
      },
    },
  ],
  [
    {
      myBeginDate: '2022-08-25 08:30',
      myEndDate: '2022-08-25 12:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Ronja',
      },
    },
    {
      myBeginDate: '2022-08-25 12:00',
      myEndDate: '2022-08-25 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Steffi',
      },
    },
  ],
  //MiniGames
  [
    {
      myBeginDate: '2022-08-25 08:30',
      myEndDate: '2022-08-25 12:00',
      rowLabel: 'Minigames',
      ganttBarConfig: {
        label: 'Patrick',
      },
    },
    {
      myBeginDate: '2022-08-25 12:00',
      myEndDate: '2022-08-25 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Tana',
      },
    },
    {
      myBeginDate: '2022-08-25 16:00',
      myEndDate: '2022-08-25 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Jonas Heinz',
      },
    },
  ],
  [
    {
      myBeginDate: '2022-08-25 12:00',
      myEndDate: '2022-08-25 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Kathi',
      },
    },
    {
      myBeginDate: '2022-08-25 16:00',
      myEndDate: '2022-08-25 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Patrick',
      },
    },
  ],
  //ORGA
  [
    {
      myBeginDate: '2022-08-25 08:30',
      myEndDate: '2022-08-25 12:00',
      rowLabel: 'Orga',
      ganttBarConfig: {
        label: 'Carbiryllim',
      },
    },
    {
      myBeginDate: '2022-08-25 12:00',
      myEndDate: '2022-08-25 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Carbiryllim',
      },
    },
    {
      myBeginDate: '2022-08-25 16:00',
      myEndDate: '2022-08-25 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Carbiryllim',
      },
    },
  ],
  //Gruppentreff
  [
    {
      myBeginDate: '2022-08-25 15:00',
      myEndDate: '2022-08-25 16:00',
      rowLabel: 'Gruppentreff',
      ganttBarConfig: {
        label: 'Payne',
      },
    },
  ],
  [
    {
      myBeginDate: '2022-08-25 15:00',
      myEndDate: '2022-08-25 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Michael',
      },
    },
  ],
  //Bühne
  [
    {
      myBeginDate: '2022-08-25 15:00',
      myEndDate: '2022-08-25 16:00',
      rowLabel: 'Bühne',
      ganttBarConfig: {
        label: 'Philip',
      },
    },
  ],
  [
    {
      myBeginDate: '2022-08-25 15:00',
      myEndDate: '2022-08-25 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Rebecca / "Vi"',
      },
    },
  ],
];

export const friday = [
  //Helfer 1
  [
    {
      myBeginDate: '2022-08-26 08:30',
      myEndDate: '2022-08-26 12:00',
      rowLabel: 'Helfer',
      ganttBarConfig: {
        label: 'Melanie',
      },
    },
    {
      myBeginDate: '2022-08-26 12:00',
      myEndDate: '2022-08-26 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Stephan Dupont',
      },
    },
    {
      myBeginDate: '2022-08-26 16:00',
      myEndDate: '2022-08-26 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Rebecca',
      },
    },
  ],
  //Helfer 2
  [
    {
      myBeginDate: '2022-08-26 08:30',
      myEndDate: '2022-08-26 12:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Matthias Pößnecker',
      },
    },
    {
      myBeginDate: '2022-08-26 12:00',
      myEndDate: '2022-08-26 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Vanni',
      },
    },
    {
      myBeginDate: '2022-08-26 16:00',
      myEndDate: '2022-08-26 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Matthias Pößnecker',
      },
    },
  ],
  //Helfer 3
  [
    {
      myBeginDate: '2022-08-26 12:00',
      myEndDate: '2022-08-26 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Patrick',
      },
    },
    {
      myBeginDate: '2022-08-26 16:00',
      myEndDate: '2022-08-26 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Markus',
      },
    },
  ],
  //SPRINGER
  [
    {
      myBeginDate: '2022-08-26 08:30',
      myEndDate: '2022-08-26 12:00',
      rowLabel: 'Springer',
      ganttBarConfig: {
        label: 'Achim',
      },
    },
    {
      myBeginDate: '2022-08-26 12:00',
      myEndDate: '2022-08-26 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Philip',
      },
    },
    {
      myBeginDate: '2022-08-26 16:00',
      myEndDate: '2022-08-26 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Steffi ♥',
      },
    },
  ],
  //Fotograf
  [
    {
      myBeginDate: '2022-08-26 08:30',
      myEndDate: '2022-08-26 12:00',
      rowLabel: 'Fotograf',
      ganttBarConfig: {
        label: 'Sophie',
      },
    },
    {
      myBeginDate: '2022-08-26 12:00',
      myEndDate: '2022-08-26 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Levi Kolb',
      },
    },
    {
      myBeginDate: '2022-08-26 16:00',
      myEndDate: '2022-08-26 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Jessica Lach',
      },
    },
  ],
  //Fotohelfer
  [
    {
      myBeginDate: '2022-08-26 08:30',
      myEndDate: '2022-08-26 12:00',
      rowLabel: 'Fotohelfer',
      ganttBarConfig: {
        label: 'Tamara',
      },
    },
    {
      myBeginDate: '2022-08-26 12:00',
      myEndDate: '2022-08-26 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Joshua',
      },
    },
    {
      myBeginDate: '2022-08-26 16:00',
      myEndDate: '2022-08-26 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Tamara',
      },
    },
  ],
  //Selfiebude
  [
    {
      myBeginDate: '2022-08-26 08:30',
      myEndDate: '2022-08-26 12:00',
      rowLabel: 'Selfiebude',
      ganttBarConfig: {
        label: 'Pooyo',
      },
    },
    {
      myBeginDate: '2022-08-26 12:00',
      myEndDate: '2022-08-26 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Alyssa',
      },
    },
    {
      myBeginDate: '2022-08-26 16:00',
      myEndDate: '2022-08-26 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Jessica Lach',
      },
    },
  ],
  //Cosplayer
  [
    {
      myBeginDate: '2022-08-26 08:30',
      myEndDate: '2022-08-26 12:00',
      rowLabel: 'Cosplayer',
      ganttBarConfig: {
        label: 'Jonas Heinz',
      },
    },
    {
      myBeginDate: '2022-08-26 12:00',
      myEndDate: '2022-08-26 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Victoria Hasch',
      },
    },
  ],
  [
    {
      myBeginDate: '2022-08-26 08:30',
      myEndDate: '2022-08-26 12:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Rebecca',
      },
    },
    {
      myBeginDate: '2022-08-26 12:00',
      myEndDate: '2022-08-26 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Ronja',
      },
    },
  ],
  //MiniGames
  [
    {
      myBeginDate: '2022-08-26 08:30',
      myEndDate: '2022-08-26 12:00',
      rowLabel: 'Minigames',
      ganttBarConfig: {
        label: 'Markus',
      },
    },
    {
      myBeginDate: '2022-08-26 12:00',
      myEndDate: '2022-08-26 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Tana',
      },
    },
    {
      myBeginDate: '2022-08-26 16:00',
      myEndDate: '2022-08-26 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Sophie',
      },
    },
  ],
  [
    {
      myBeginDate: '2022-08-26 12:00',
      myEndDate: '2022-08-26 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Kathi',
      },
    },
    {
      myBeginDate: '2022-08-26 16:00',
      myEndDate: '2022-08-26 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Pooyo',
      },
    },
  ],
  //ORGA
  [
    {
      myBeginDate: '2022-08-26 08:30',
      myEndDate: '2022-08-26 12:00',
      rowLabel: 'Orga',
      ganttBarConfig: {
        label: 'Carbiryllim',
      },
    },
    {
      myBeginDate: '2022-08-26 12:00',
      myEndDate: '2022-08-26 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Carbiryllim',
      },
    },
    {
      myBeginDate: '2022-08-26 16:00',
      myEndDate: '2022-08-26 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Carbiryllim',
      },
    },
  ],
  //Gruppentreff
  [
    {
      myBeginDate: '2022-08-26 15:00',
      myEndDate: '2022-08-26 16:00',
      rowLabel: 'Gruppentreff',
      ganttBarConfig: {
        label: 'Payne',
      },
    },
  ],
  [
    {
      myBeginDate: '2022-08-26 15:00',
      myEndDate: '2022-08-26 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Michael',
      },
    },
  ],
];

export const saturday = [
  //Helfer 1
  [
    {
      myBeginDate: '2022-08-27 08:30',
      myEndDate: '2022-08-27 12:00',
      rowLabel: 'Helfer',
      ganttBarConfig: {
        label: 'Vanni',
      },
    },
    {
      myBeginDate: '2022-08-27 12:00',
      myEndDate: '2022-08-27 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Stephan Dupont',
      },
    },
    {
      myBeginDate: '2022-08-27 16:00',
      myEndDate: '2022-08-27 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Vanni',
      },
    },
  ],
  //Helfer 2
  [
    {
      myBeginDate: '2022-08-27 08:30',
      myEndDate: '2022-08-27 12:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Markus',
      },
    },
    {
      myBeginDate: '2022-08-27 12:00',
      myEndDate: '2022-08-27 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Melanie',
      },
    },
    {
      myBeginDate: '2022-08-27 16:00',
      myEndDate: '2022-08-27 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Matthias Pößnecker',
      },
    },
  ],
  //Helfer 3
  [
    {
      myBeginDate: '2022-08-27 12:00',
      myEndDate: '2022-08-27 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Philip',
      },
    },
    {
      myBeginDate: '2022-08-27 16:00',
      myEndDate: '2022-08-27 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Tony Friedrich',
      },
    },
  ],
  //SPRINGER
  [
    {
      myBeginDate: '2022-08-27 08:30',
      myEndDate: '2022-08-27 12:00',
      rowLabel: 'Springer',
      ganttBarConfig: {
        label: 'Alcesh',
      },
    },
    {
      myBeginDate: '2022-08-27 12:00',
      myEndDate: '2022-08-27 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Achim',
      },
    },
    {
      myBeginDate: '2022-08-27 16:00',
      myEndDate: '2022-08-27 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Alcesh',
      },
    },
  ],
  //Fotograf
  [
    {
      myBeginDate: '2022-08-27 08:30',
      myEndDate: '2022-08-27 12:00',
      rowLabel: 'Fotograf',
      ganttBarConfig: {
        label: 'Levi',
      },
    },
    {
      myBeginDate: '2022-08-27 12:00',
      myEndDate: '2022-08-27 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Sophie',
      },
    },
    {
      myBeginDate: '2022-08-27 16:00',
      myEndDate: '2022-08-27 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Joshua Drescher',
      },
    },
  ],
  //Fotohelfer
  [
    {
      myBeginDate: '2022-08-27 08:30',
      myEndDate: '2022-08-27 12:00',
      rowLabel: 'Fotohelfer',
      ganttBarConfig: {
        label: 'Tamara',
      },
    },
    {
      myBeginDate: '2022-08-27 12:00',
      myEndDate: '2022-08-27 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Kate',
      },
    },
    {
      myBeginDate: '2022-08-27 16:00',
      myEndDate: '2022-08-27 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Lisa Brambach',
      },
    },
  ],
  //Selfiebude
  [
    {
      myBeginDate: '2022-08-27 08:30',
      myEndDate: '2022-08-27 12:00',
      rowLabel: 'Selfiebude',
      ganttBarConfig: {
        label: 'Alyssa',
      },
    },
    {
      myBeginDate: '2022-08-27 12:00',
      myEndDate: '2022-08-27 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Jasmin Fikus',
      },
    },
    {
      myBeginDate: '2022-08-27 16:00',
      myEndDate: '2022-08-27 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Anika Glodek',
      },
    },
  ],
  //Cosplayer
  [
    {
      myBeginDate: '2022-08-27 08:30',
      myEndDate: '2022-08-27 12:00',
      rowLabel: 'Cosplayer',
      ganttBarConfig: {
        label: 'Marek ♥',
      },
    },
    {
      myBeginDate: '2022-08-27 12:00',
      myEndDate: '2022-08-27 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Ronja',
      },
    },
  ],
  [
    {
      myBeginDate: '2022-08-27 08:30',
      myEndDate: '2022-08-27 12:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Victoria',
      },
    },
    {
      myBeginDate: '2022-08-27 12:00',
      myEndDate: '2022-08-27 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Rebecca',
      },
    },
  ],
  //MiniGames
  [
    {
      myBeginDate: '2022-08-27 08:30',
      myEndDate: '2022-08-27 12:00',
      rowLabel: 'Minigames',
      ganttBarConfig: {
        label: 'Pooyo',
      },
    },
    {
      myBeginDate: '2022-08-27 12:00',
      myEndDate: '2022-08-27 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Tana',
      },
    },
    {
      myBeginDate: '2022-08-27 16:00',
      myEndDate: '2022-08-27 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Marek ♥',
      },
    },
  ],
  [
    {
      myBeginDate: '2022-08-27 12:00',
      myEndDate: '2022-08-27 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Kathi',
      },
    },
    {
      myBeginDate: '2022-08-27 16:00',
      myEndDate: '2022-08-27 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Markus',
      },
    },
  ],
  //ORGA
  [
    {
      myBeginDate: '2022-08-27 08:30',
      myEndDate: '2022-08-27 12:00',
      rowLabel: 'Orga',
      ganttBarConfig: {
        label: 'Carbiryllim',
      },
    },
    {
      myBeginDate: '2022-08-27 12:00',
      myEndDate: '2022-08-27 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Carbiryllim',
      },
    },
    {
      myBeginDate: '2022-08-27 16:00',
      myEndDate: '2022-08-27 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Carbiryllim',
      },
    },
  ],
  //Gruppentreff
  [
    {
      myBeginDate: '2022-08-27 15:00',
      myEndDate: '2022-08-27 16:00',
      rowLabel: 'Gruppentreff',
      ganttBarConfig: {
        label: 'Payne',
      },
    },
  ],
  [
    {
      myBeginDate: '2022-08-27 15:00',
      myEndDate: '2022-08-27 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Michael',
      },
    },
  ],
  //Catch the Character
  [
    {
      myBeginDate: '2022-08-27 12:30',
      myEndDate: '2022-08-27 16:00',
      rowLabel: 'Catch the Character',
      ganttBarConfig: {
        label: 'Marek  ♥',
      },
    },
  ],
  [
    {
      myBeginDate: '2022-08-27 12:30',
      myEndDate: '2022-08-27 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Alyssa',
      },
    },
  ],
  [
    {
      myBeginDate: '2022-08-27 12:30',
      myEndDate: '2022-08-27 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Rebecca',
      },
    },
  ],
  [
    {
      myBeginDate: '2022-08-27 12:30',
      myEndDate: '2022-08-27 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Jonas',
      },
    },
  ],
  [
    {
      myBeginDate: '2022-08-27 12:30',
      myEndDate: '2022-08-27 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Kate',
      },
    },
  ],
];

export const sunday = [
  //Helfer 1
  [
    {
      myBeginDate: '2022-08-28 08:30',
      myEndDate: '2022-08-28 12:00',
      rowLabel: 'Helfer',
      ganttBarConfig: {
        label: 'Lisa',
      },
    },
    {
      myBeginDate: '2022-08-28 12:00',
      myEndDate: '2022-08-28 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Stephan Dupont',
      },
    },
    {
      myBeginDate: '2022-08-28 16:00',
      myEndDate: '2022-08-28 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Victoria',
      },
    },
  ],
  //Helfer 2
  [
    {
      myBeginDate: '2022-08-28 08:30',
      myEndDate: '2022-08-28 12:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Tobias Grützner',
      },
    },
    {
      myBeginDate: '2022-08-28 12:00',
      myEndDate: '2022-08-28 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Vanni',
      },
    },
    {
      myBeginDate: '2022-08-28 16:00',
      myEndDate: '2022-08-28 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Tobias Grützner',
      },
    },
  ],
  //Helfer 3
  [
    {
      myBeginDate: '2022-08-28 08:30',
      myEndDate: '2022-08-28 12:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Ronja',
      },
    },
    {
      myBeginDate: '2022-08-28 12:00',
      myEndDate: '2022-08-28 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Tony Friedrich',
      },
    },
    {
      myBeginDate: '2022-08-28 16:00',
      myEndDate: '2022-08-28 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Matthias Pößnecker',
      },
    },
  ],
  //SPRINGER
  [
    {
      myBeginDate: '2022-08-28 08:30',
      myEndDate: '2022-08-28 12:00',
      rowLabel: 'Springer',
      ganttBarConfig: {
        label: 'Alcesh',
      },
    },
    {
      myBeginDate: '2022-08-28 12:00',
      myEndDate: '2022-08-28 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Philip',
      },
    },
    {
      myBeginDate: '2022-08-28 16:00',
      myEndDate: '2022-08-28 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Alcesh',
      },
    },
  ],
  //Fotograf
  [
    {
      myBeginDate: '2022-08-28 08:30',
      myEndDate: '2022-08-28 12:00',
      rowLabel: 'Fotograf',
      ganttBarConfig: {
        label: 'Sophie',
      },
    },
    {
      myBeginDate: '2022-08-28 12:00',
      myEndDate: '2022-08-28 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Tamara',
      },
    },
    {
      myBeginDate: '2022-08-28 16:00',
      myEndDate: '2022-08-28 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Levi',
      },
    },
  ],
  //Fotohelfer
  [
    {
      myBeginDate: '2022-08-28 08:30',
      myEndDate: '2022-08-28 12:00',
      rowLabel: 'Fotohelfer',
      ganttBarConfig: {
        label: 'Alexander Friedrich',
      },
    },
    {
      myBeginDate: '2022-08-28 12:00',
      myEndDate: '2022-08-28 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Lisa Brambach',
      },
    },
    {
      myBeginDate: '2022-08-28 16:00',
      myEndDate: '2022-08-28 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Alexander Friedrich',
      },
    },
  ],
  //Selfiebude
  [
    {
      myBeginDate: '2022-08-28 08:30',
      myEndDate: '2022-08-28 12:00',
      rowLabel: 'Selfiebude',
      ganttBarConfig: {
        label: 'Jasmin Fikus',
      },
    },
    {
      myBeginDate: '2022-08-28 12:00',
      myEndDate: '2022-08-28 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Alyssa',
      },
    },
    {
      myBeginDate: '2022-08-28 16:00',
      myEndDate: '2022-08-28 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Jasmin Fikus',
      },
    },
  ],
  //Cosplayer
  [
    {
      myBeginDate: '2022-08-28 08:30',
      myEndDate: '2022-08-28 12:00',
      rowLabel: 'Cosplayer',
      ganttBarConfig: {
        label: 'Marek ♥',
      },
    },
    {
      myBeginDate: '2022-08-28 12:00',
      myEndDate: '2022-08-28 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Jonas',
      },
    },
  ],
  [
    {
      myBeginDate: '2022-08-28 08:30',
      myEndDate: '2022-08-28 12:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Melanie',
      },
    },
    {
      myBeginDate: '2022-08-28 12:00',
      myEndDate: '2022-08-28 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Anika Glodek',
      },
    },
  ],
  //MiniGames
  [
    {
      myBeginDate: '2022-08-28 08:30',
      myEndDate: '2022-08-28 12:00',
      rowLabel: 'Minigames',
      ganttBarConfig: {
        label: 'Markus',
      },
    },
    {
      myBeginDate: '2022-08-28 12:00',
      myEndDate: '2022-08-28 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Tana',
      },
    },
    {
      myBeginDate: '2022-08-28 16:00',
      myEndDate: '2022-08-28 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Joshua',
      },
    },
  ],
  [
    {
      myBeginDate: '2022-08-28 12:00',
      myEndDate: '2022-08-28 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Kathi',
      },
    },
    {
      myBeginDate: '2022-08-28 16:00',
      myEndDate: '2022-08-28 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Lisa Schwinn',
      },
    },
  ],
  //ORGA
  [
    {
      myBeginDate: '2022-08-28 08:30',
      myEndDate: '2022-08-28 12:00',
      rowLabel: 'Orga',
      ganttBarConfig: {
        label: 'Carbiryllim',
      },
    },
    {
      myBeginDate: '2022-08-28 12:00',
      myEndDate: '2022-08-28 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Carbiryllim',
      },
    },
    {
      myBeginDate: '2022-08-28 16:00',
      myEndDate: '2022-08-28 20:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Carbiryllim',
      },
    },
  ],
  //Gruppentreff
  [
    {
      myBeginDate: '2022-08-28 15:00',
      myEndDate: '2022-08-28 16:00',
      rowLabel: 'Gruppentreff',
      ganttBarConfig: {
        label: 'Payne',
      },
    },
  ],
  [
    {
      myBeginDate: '2022-08-28 15:00',
      myEndDate: '2022-08-28 16:00',
      rowLabel: '',
      ganttBarConfig: {
        label: 'Michael',
      },
    },
  ],
];
