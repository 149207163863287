<template>
  <ComponentLoader :components="getSpecificPage" />
</template>

<script>
import { mapGetters } from "vuex";
import ComponentLoader from "../components/ComponentLoader.vue";

export default {
  name: "base-page",
  components: {
    ComponentLoader,
  },
  computed: {
    ...mapGetters(["getPagesEN", "getPagesDE", "getLanguage"]),

    getSpecificPage() {
      const page =
        this.getLanguage == "de-DE" ? this.getPagesDE : this.getPagesEN;

      const specificPage = page.find((page) => {
        const pageSlug =
          page.fields.slug !== "/" ? "/" + page.fields.slug : "/";
        return pageSlug == window.location.pathname;
      });
      return specificPage?.fields?.reference;
    },
  },
};
</script>
